import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { credentials } from '@shootsta/client-auth';
import UserAvailabilityComponent from '../components/UserAvailability';
import { getUserTeamApi, updateUserApi } from '../api';

function UserAvailability(props: Object) {
  const { selectedOrganisation: { organisationId } } = props;
  const { userId, isAvailable } = credentials.get().user;

  return (
    <RequestProvider
      requests={{
        updateUser: updateUserApi(userId),
        getUserTeam: getUserTeamApi({ userId, organisationId, type: 'EDITING' })
      }}
    >
      {requests => (
        <UserAvailabilityComponent
          userId={userId}
          isAvailable={isAvailable}
          {...props}
          {...requests}
        />
      )}
    </RequestProvider>
  );
}

export default UserAvailability;
