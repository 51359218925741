// @flow

import React, { memo } from 'react';
import type { Node } from 'react';
import Background from '../Background';
import '../../styles/_login.scss';

type WorkspaceWrapperProps = {
  children: Node,
  backgroundType: 'person' | 'lost' | 'create'
};

const WorkspaceWrapper = ({ children, backgroundType }: WorkspaceWrapperProps) => (
  <div className="login">
    <div className="login__container" data-cy="workspace-container">
      <div className="login__left-content">
        <div>
          {children}
        </div>
      </div>
      <Background type={backgroundType} />
    </div>
  </div>
);

// eslint-disable-next-line no-undef
export default memo<WorkspaceWrapperProps>(WorkspaceWrapper);
