import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import type { Node } from 'react';

type AppContainerProps = {
  children: Node
};

export default function AppContainer({ children }: AppContainerProps) {
  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  );
}
