import { gql } from 'apollo-boost';

export const setNotificationsReadMutation = gql`
  mutation SetNotificationsRead($notificationIds: [String]!) {
    setNotificationsRead(notificationIds: $notificationIds)
  }
`;

export default () => ({
  mutation: setNotificationsReadMutation,
  module: 'notifications',
  buildVariables: (
    { notificationIds }: { notificationIds: string[] }
  ) => ({ notificationIds })
});
