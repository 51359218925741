import React from 'react';
import { withRouter } from 'react-router-dom';
import { credentials } from '@shootsta/client-auth';
import { ApolloConsumer } from 'react-apollo';
import { RequestProvider } from '@shootsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import OutputsListComponent from '../components/OutputsList';
import { getTrafficOutputsApi } from '../api';

function OutputsList({ history }: { history: History }) {
  const { userId } = credentials.get().user;

  return (
    <ApolloConsumer>
      {apolloClient => (
        <RequestProvider requests={{
          getTrafficOutputs: getTrafficOutputsApi({
            filter: { EDITOR: [userId], STATUS: ['PENDING_MASTER', 'EDITING'] },
            limit: 2
          })
        }}
        >
          {routes => (
            <OutputsListComponent
              history={history}
              apolloClient={apolloClient}
              {...routes}
            />
          )}
        </RequestProvider>
      )}
    </ApolloConsumer>
  );
}

export default withRouter(OutputsList);
