/* eslint-disable no-nested-ternary */

import React from 'react';
import cx from 'classnames';
import { Text, List, Carousel, ActionPopup, ElevatedContainer, Image, Link, NUMERIC_MEDIA_SIZES } from '@shootsta/common-react';
import { STATUS_COLORS_MAP, RECENT_CAROUSELS } from '../../constants';
import { getStatusForVideo, useWindowDimensions } from '../../../../utils';
import './_recent-carousel.scss';

const MEDIA_SIZES_SMALL = NUMERIC_MEDIA_SIZES.small;

type RecentCarouselProps = {
  carouselTitle: string,
  carouselCards: Array<Object>,
  productName: string,
  carouselTabPath: string,
  itemClick: Function
};

function RecentCarousel({
  carouselTitle,
  carouselCards,
  itemClick,
  carouselTabPath,
  productName
}: RecentCarouselProps) {
  const { width } = useWindowDimensions();

  const cardAction = (action: string, data: Object) => {
    if (action === 'open') {
      const { videoId, outputId } = data;

      const pathOptions = {
        Pro: `/pro/project/${videoId}`,
        Cast: `/cast/complete/${videoId}/${outputId}`,
        Elevate: `/elevate/${videoId}`
      };

      const path = pathOptions[productName] || pathOptions.Pro;

      itemClick(path);
    }
  };

  const projectCardItem = (card) => {
    const { outputId, readableId, status, video, assetThumbnailUrl } = card;
    const projectStatus = getStatusForVideo(status.name);

    return (
      <List.CardItem key={outputId} {...card} className={cx({ 'card-item-single': (productName === RECENT_CAROUSELS.PRO.PRODUCT_NAME && carouselCards.length === 1) })}>
        <Image
          backgroundSize="cover"
          imageUrl={assetThumbnailUrl}
          defaultImageIcon="video"
          width="100%"
          onClick={() => cardAction('open', card)}
        />
        {productName === RECENT_CAROUSELS.PRO.PRODUCT_NAME
          && (
            <>
              <div className="content-status" style={{ backgroundColor: STATUS_COLORS_MAP[projectStatus] }}>
                {projectStatus.replace('_', ' ').toLowerCase()}
              </div>
              <Text className="readable-id">{readableId}</Text>
            </>
          )
        }
        <div className="content-title">
          <Text heading4>{video.name}</Text>
        </div>
        <div className="content-menu">
          <ActionPopup
            options={[
              {
                action: () => cardAction('open', card),
                label: `Open ${productName === RECENT_CAROUSELS.PRO.PRODUCT_NAME ? 'Project' : productName}`
              }
            ]}
          />
        </div>
      </List.CardItem>
    );
  };

  return (
    <div className="recent-carousel">
      <ElevatedContainer rounded className="homepage-carousel-container">
        <div className="recent-carousel-container__header">
          <Text heading4 className="homepage-carousel-container__header-title">{carouselTitle}</Text>
          <Link to={carouselTabPath}>View All</Link>
        </div>
        <div className="recent-carousel__body">
          <div className="recent-carousel__body__description">
            <div className="recent-carousel__body__description__name">
              <Carousel itemWidth={320} slidesToShow={width < MEDIA_SIZES_SMALL && 1}>
                {carouselCards.length ? carouselCards.map(card => projectCardItem(card)) : null}
              </Carousel>
            </div>
          </div>
        </div>
      </ElevatedContainer>
    </div>
  );
}

export default RecentCarousel;
