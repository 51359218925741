// @flow
import React from 'react';
import _groupBy from 'lodash.groupby';
import _isEmpty from 'lodash.isempty';
import { formatDate } from '@shootsta/get-moment';
import { ClosablePage, Text } from '@shootsta/common-react';
import { useInfiniteScroll } from '../../../../../utils/hooks';
import NotificationItem from '../NotificationItem';
import { sortLatestCreated } from '../../../../../utils/notification';
import type { Notification } from '../../../../../types/notification';
import { getRelativeDate } from '../../../../../utils/relativeDate';
import './styles/_view-all-notifications.scss';

type Props = {
  notifications: Notification[],
  unreadNotificationsCount: number,
  onCloseViewAllNotifications: Function,
  onLinkSelect: Function,
  fetchNotifications: Function,
  getMarkAllAsReadLink: Function
};

const ROOT_CLASS = 'view-all-notifications';

function displayedCreatedAtGenerator(createdAt: string) {
  return formatDate(createdAt, 'h:mma');
}

export function dateFromNow({ createdAt }: Notification) {
  const { isToday, isYesterday } = getRelativeDate(createdAt);

  if (isToday) { return 'Today'; }

  if (isYesterday) { return 'Yesterday'; }

  return formatDate(createdAt, 'ddd Do MMMM YYYY');
}

export default function ViewAllNotifications({
  notifications,
  onCloseViewAllNotifications,
  onLinkSelect,
  fetchNotifications,
  unreadNotificationsCount,
  getMarkAllAsReadLink
}: Props) {
  useInfiniteScroll(fetchNotifications);

  const groupedNotifications = notifications
    ? _groupBy(notifications, dateFromNow)
    : {};

  return (
    <ClosablePage onClose={onCloseViewAllNotifications}>
      <div className={ROOT_CLASS}>
        <div className={`${ROOT_CLASS}__header`}>
          {unreadNotificationsCount > 0 && (
            <div className={`${ROOT_CLASS}__header__indicator`}>
              <div className={`${ROOT_CLASS}__header__indicator__text`}>
                {unreadNotificationsCount > 99 ? 99 : unreadNotificationsCount}
              </div>
            </div>
          )}
          <Text heading3 noMargin className={`${ROOT_CLASS}__header__title`}>Notifications</Text>
        </div>
        <div className={`${ROOT_CLASS}__content`}>
          {getMarkAllAsReadLink(ROOT_CLASS)}
          {!_isEmpty(groupedNotifications)
            ? Object.keys(groupedNotifications).map(key => (
              <section className={`${ROOT_CLASS}__section`} key={key}>
                <Text heading5 className={`${ROOT_CLASS}__section__title`}>{key}</Text>
                {
                  groupedNotifications[key].sort(sortLatestCreated).map((notification) => {
                    const {
                      notificationId,
                      fullName,
                      resource,
                      link,
                      read,
                      type,
                      createdAt
                    } = notification;

                    return (
                      <div key={notification.notificationId}>
                        <NotificationItem
                          notificationId={notificationId}
                          fullName={fullName}
                          resource={resource}
                          link={link}
                          read={read}
                          type={type}
                          createdAt={createdAt}
                          displayedCreatedAt={displayedCreatedAtGenerator(createdAt)}
                          onLinkSelect={onLinkSelect}
                          className={`${ROOT_CLASS}__section`}
                        />
                      </div>
                    );
                  })
                }
              </section>
            ))
            : (
              <div className={`${ROOT_CLASS}__section__no-items`}>
                <Text placeholder centered>No previous notifications</Text>
              </div>
            )}
        </div>
      </div>
    </ClosablePage>
  );
}
