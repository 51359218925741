import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import globalConfig from 'react-global-configuration';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import _isEmpty from 'lodash.isempty';
import { credentials, apiClient } from '@shootsta/client-auth';
import getOrgAlias from '@shootsta/get-alias';
import { ViewportProvider, withFlagsProvider } from '@shootsta/common-react';
import config from './config';
import {
  VIDYARD_ALIAS,
  ASIAMARKETENTRY_ALIAS,
  UNDERCOVERINC_ALIAS,
  SHOOTSTA_PREVIEW_WORKSPACES,
  SHOOTSTA_HYPER_BATCH_WORKSPACES
} from './common/constants';
import 'what-input';
import { AppContainer } from './common/containers';
import Routes from './routes';
import './styles/_main.scss';

const { user } = credentials.get() || {};
const orgAlias = getOrgAlias();

window.launchDarklyClient.waitForInitialization().then(() => {
  const flagValue = window.launchDarklyClient.variation('enable-hubspot-service-hub', false);

  if (flagValue) {
    return;
  }

  const userIdForIntercom = _isEmpty(user) ? '' : user.userId;
  const userForIntercom = { ...user, user_id: userIdForIntercom };

  window.Intercom('boot', {
    app_id: 'uvq3bdav',
    custom_launcher_selector: '#nav-item-livesupport',
    ...userForIntercom
  });
});

function onConversationsAPIReady() {
  const {
    email = '',
    firstName: firstname = '',
    lastName: lastname = ''
  } = !_isEmpty(user) ? user : {};

  // eslint-disable-next-line no-multi-assign
  const _hsq = window._hsq = window._hsq || [];

  _hsq.push(['identify', {
    email,
    firstname,
    lastname
  }]);
  _hsq.push(['trackPageView']);

  setTimeout(() => {
    window.HubSpotConversations.widget.refresh();

    const hubspotContainer = document.getElementById('hubspot-messages-iframe-container');

    if (hubspotContainer) {
      hubspotContainer.classList.add('hubspot-widget-launcher');
    }
  }, 1000);
}

function sendHubSpotIdentify() {
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
}

window.launchDarklyClient.waitForInitialization().then(() => {
  const flagValue = window.launchDarklyClient.variation('enable-hubspot-service-hub', false);

  if (!flagValue) {
    return;
  }

  sendHubSpotIdentify();
});

// eslint-disable-next-line no-unused-expressions
window.relicxSDK?.identify(
  user ? user.userId : 'unknown-user-id',
  {
    email: user ? user.email : 'unknown@user.com',
    user_name: user ? `${user.firstName} ${user.lastName}` : 'Unauthenticated User',
    tenant: orgAlias
  }
);

const NO_CAST_WORKSPACES = [UNDERCOVERINC_ALIAS, VIDYARD_ALIAS];

const HUBSPOT_WORKSPACES = [...SHOOTSTA_PREVIEW_WORKSPACES];

const STOCK_ASSETS_WORKSPACES = [...SHOOTSTA_PREVIEW_WORKSPACES];

const VIDYARD_ELEVATE_EXPORT_WORKSPACES = [...SHOOTSTA_PREVIEW_WORKSPACES];

const CREATE_ORGANISATION_FROM_SF_WORKSPACE = [...SHOOTSTA_PREVIEW_WORKSPACES];

const CLIENT_FACING_STATISTICS_REPORTS_WORKSPACE = [...SHOOTSTA_PREVIEW_WORKSPACES];

const ELEVATE_DESKTOP_RECORDING_WORKSPACES = [
  ...SHOOTSTA_PREVIEW_WORKSPACES,
  ASIAMARKETENTRY_ALIAS
];

const ELEVATE_TAGS_WORKSPACES = [...SHOOTSTA_PREVIEW_WORKSPACES];

const ELEVATE_TEMPLATE_WORKSPACES = [...SHOOTSTA_PREVIEW_WORKSPACES];

// Enable Cast for Shootsta only
const HIDE_FEATURES = {
  ...config.HIDE_FEATURES,
  cast: NO_CAST_WORKSPACES.includes(orgAlias),
  stockAssets: STOCK_ASSETS_WORKSPACES.includes(orgAlias)
    ? false
    : config.HIDE_FEATURES.stockAssets,
  hubspot: HUBSPOT_WORKSPACES.includes(orgAlias) ? false : config.HIDE_FEATURES.hubspot,
  shareElevateVidyard: VIDYARD_ELEVATE_EXPORT_WORKSPACES.includes(orgAlias)
    ? false
    : config.HIDE_FEATURES.shareElevateVidyard,
  clientFacingReports: CLIENT_FACING_STATISTICS_REPORTS_WORKSPACE.includes(orgAlias)
    ? false
    : config.HIDE_FEATURES.clientFacingReports,
  CREATE_ORGANISATION_FROM_SF:
    CREATE_ORGANISATION_FROM_SF_WORKSPACE.includes(orgAlias)
      && process.env.NODE_ENV !== 'production'
      ? false
      : config.HIDE_FEATURES.CREATE_ORGANISATION_FROM_SF,
  hideManageSplashPageTemplates: false, // Internal admin manage splash templates
  elevateDesktopRecording: ELEVATE_DESKTOP_RECORDING_WORKSPACES.includes(orgAlias)
    ? false
    : config.HIDE_FEATURES.elevateDesktopRecording,
  manageElevateTags: ELEVATE_TAGS_WORKSPACES.includes(orgAlias)
    ? false
    : config.HIDE_FEATURES.manageElevateTags,
  privateElevateTags: true,
  ...(ELEVATE_TEMPLATE_WORKSPACES.includes(orgAlias)
    ? {
      manageWorkspaceElevateTemplates: false,
      manageWorkspaceSplashPages: false
    }
    : {
      manageWorkspaceElevateTemplates: config.HIDE_FEATURES.manageWorkspaceElevateTemplates,
      manageWorkspaceSplashPages: config.HIDE_FEATURES.manageWorkspaceSplashPages
    }),
  showElevateHyperBatch: SHOOTSTA_HYPER_BATCH_WORKSPACES.includes(orgAlias)
    ? true
    : config.HIDE_FEATURES.showElevateHyperBatch
};

globalConfig.set(
  {
    serviceEndpoint: config.serviceEndpoint,
    identityEndpoint: config.identityEndpoint,
    featureFlagServiceClientId: config.featureFlagServiceClientId,
    shootstaDomainSegments: config.shootstaDomainSegments,
    skipNotifications: Boolean(String(config.skipNotifications) === 'true'),
    // $FlowFixMe
    DOWNLOAD_MAC_APP_LINK: config.DOWNLOAD_MAC_APP_LINK,
    HIDE_FEATURES
  },
  { freeze: false }
);

const root: ?Element = document.getElementById('root');

const App = withFlagsProvider(DragDropContext(HTML5Backend)(() => (
  <ApolloProvider
    client={apiClient({
      apiEndpoint: config.apiEndpoint,
      wsEndpoint: config.subscriptionsEndpoint
    })}
  >
    <ApolloConsumer>
      {client => (
        <ViewportProvider>
          <AppContainer makeQuery={client.query}>
            <Routes />
          </AppContainer>
        </ViewportProvider>
      )}
    </ApolloConsumer>
  </ApolloProvider>
)));

if (root != null) {
  ReactDOM.render(<App />, root);
}
