// @flow

import React, { Component } from 'react';
import type { RouterHistory } from 'react-router-dom';
import ChooseWorkspaceList from './ChooseWorkspaceList';
import { WorkspaceWrapper } from './Workspace';

type ChooseWorkspaceProps = {
  getUserOrganisations: Function,
  email: string,
  history: RouterHistory
};

type ChooseWorkspaceState = {
  organisations: Object[]
};

export default class ChooseWorkspace extends Component<ChooseWorkspaceProps, ChooseWorkspaceState> {
  constructor(props: ChooseWorkspaceProps) {
    super(props);

    this.state = {
      organisations: []
    };
  }

  componentDidMount() {
    this.fetchOrganisations();
  }

  fetchOrganisations = async () => {
    const { getUserOrganisations, email } = this.props;

    const { data, error } = await getUserOrganisations({ email });

    if (error || !data || !data.getUserOrganisations) {
      return;
    }

    this.setState({ organisations: data.getUserOrganisations });
  }

  useAnotherAddress = () => {
    const { history } = this.props;

    history.push('/get-workspaces');
  }

  render() {
    const { organisations } = this.state;

    return (
      <WorkspaceWrapper backgroundType="create">
        <ChooseWorkspaceList
          orgs={organisations}
          useAnotherAddress={this.useAnotherAddress}
        />
      </WorkspaceWrapper>
    );
  }
}
