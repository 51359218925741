module.exports ={
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001/api/',
  wsEndpoint: process.env.REACT_APP_WS_ENDPOINT || 'ws://localhost:5000/graphql',
  serviceEndpoint: process.env.REACT_APP_SERVICE_ENDPOINT || 'http://localhost:3008/',
  subscriptionsEndpoint: process.env.REACT_APP_SUBSCRIPTIONS_ENDPOINT || 'ws://localhost:3011/subscriptions',
  identityEndpoint: process.env.REACT_APP_IDENTITY_ENDPOINT || 'http://localhost:3003/',
  featureFlagServiceClientId: process.env.REACT_APP_FEATURE_FLAG_SERVICE_CLIENT_ID || '6260be9f41b3481588213f7e',
  shootstaDomainSegments: process.env.REACT_APP_SHOOTSTA_DOMAIN_SEGMENTS || '0c7d217c-7ffa-4c88-a893-196e701f3c65',
  ssoApiEndpoint: process.env.REACT_APP_SSO_API_ENDPOINT || 'http://localhost:8081/saml/',
  skipNotifications: process.env.REACT_APP_SKIP_NOTIFICATIONS || 'false',
  DOWNLOAD_MAC_APP_LINK: process.env.REACT_APP_DOWNLOAD_MAC_APP_LINK,
  socketEndpoint: 'http://localhost:3026'
}
