// @flow
import React, { Component } from 'react';
import { authLocalStore, credentials } from '@shootsta/client-auth';
import { replaceBucketKeysWithUrls } from '@shootsta/client-aws-s3';
import { Icon, withFlagsConsumer } from '@shootsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import type { User } from '@shootsta/common-flow-types';
import NotificationsSummary from './NotificationsSummary';
import UserWrapper from './UserWrapper';
import SwitchOrganisationModal from './SwitchOrganisationModal';
import { clearSSOLocalStore } from '../../../../utils';
import { handleSignOut } from '../../../utils';
import '../styles/_global-header.scss';

type GlobalHeaderProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  apolloClient: Object,
  selectedOrganisation: Object,
  notifications: Object,
  history: History,
  adminMode: boolean,
  toggleAdminMode: Function,
  ghostStop: Function,
  loginViaIdentity: boolean,
  width: number,
  flags: any
};

type GlobalHeaderState = {
  userId: string,
  fullName: string,
  imageUrl: string,
  email: string,
  isGhosting: boolean,
  original: Object,
  origURL: string
};

const MAX_FULL_HEADER_WIDTH = 500;

class GlobalHeader extends Component<GlobalHeaderProps, GlobalHeaderState> {
  constructor(props: GlobalHeaderProps) {
    super(props);

    const { userId, firstName, lastName, imageUrl, email } = credentials.get().user;
    const { original = null, origURL = '' } = credentials.get();
    credentials.listenToChange(this.handleCredentialsChange);

    const fullName = `${firstName} ${lastName}`;

    this.state = {
      userId,
      fullName,
      imageUrl,
      email,
      isGhosting: Boolean(original),
      original,
      origURL
    };
  }

  componentDidMount() {
    this.getPreSignedImageUrl();
  }

  initialiseChangelog = (el: HTMLDivElement | null) => {
    if (!el) { return; }

    const { width } = this.props;

    if (width < MAX_FULL_HEADER_WIDTH) { return; }

    window.Canny('initChangelog', {
      appID: '5f4d9dcea4f8144185c24398',
      position: 'bottom',
      align: 'right'
    });
  };

  getPreSignedImageUrl = async () => {
    const { selectedOrganisation: { organisationId }, apolloClient } = this.props;
    const { imageUrl } = this.state;

    try {
      const { imageUrl: preSignedImageUrl } = await replaceBucketKeysWithUrls({
        apiClient: apolloClient,
        data: { imageUrl },
        substitutedKeys: ['imageUrl'],
        organisationId
      });

      this.setState({ imageUrl: preSignedImageUrl });
    } catch {
      // Silently handle error
    }
  };

  handleCredentialsChange = (userCredentials?: { user: ?User }) => {
    if (!userCredentials || !userCredentials.user) {
      return;
    }
    const { user } = userCredentials;
    this.setState({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`
    }, this.getPreSignedImageUrl);
  }

  handleSignOut = () => {
    const { isGhosting } = this.state;
    const { history, loginViaIdentity } = this.props;

    return handleSignOut({
      isGhosting,
      history,
      loginViaIdentity
    });
  };

  handleStopGhosting = async () => {
    const { ghostStop, history, loginViaIdentity } = this.props;
    const {
      original: {
        token,
        user,
        original = null
      },
      origURL
    } = this.state;

    clearSSOLocalStore();

    if (!loginViaIdentity) {
      return void history.push(
        `/?ghost=${btoa(JSON.stringify({ clearGhost: true, token, user, original, targetURL: origURL }))}`
      );
    }

    await ghostStop();

    const data = {
      auth: true,
      ghost: false,
      token,
      user,
      original,
      origURL
    };

    authLocalStore.save(data);

    window.location.replace(origURL);
  };

  render() {
    const {
      userId,
      fullName,
      imageUrl,
      email,
      isGhosting
    } = this.state;
    const {
      showSwitchOrganisationModal,
      selectedOrganisation,
      appSwitcherVisible,
      onCloseSwitchOrganisationModal,
      notifications,
      adminMode,
      toggleAdminMode,
      width,
      flags
    } = this.props;

    return (
      <header>
        <div className="column global-header-container">
          {
            width >= MAX_FULL_HEADER_WIDTH
              ? (
                <div
                  className="global-header-container__changes-container"
                  data-canny-changelog
                  ref={this.initialiseChangelog}
                >
                  <Icon name="flashon" size={20} />
                </div>
              )
              : null
          }
          {flags.featureX && (
            <NotificationsSummary {...notifications} />
          )}
          <UserWrapper
            userId={userId}
            fullName={fullName}
            imageUrl={imageUrl}
            isGhosting={isGhosting}
            email={email}
            handleSignOut={this.handleSignOut}
            handleStopGhosting={this.handleStopGhosting}
            showSwitchOrganisationModal={showSwitchOrganisationModal}
            selectedOrganisation={selectedOrganisation}
            adminMode={adminMode}
            toggleAdminMode={toggleAdminMode}
          />
          <SwitchOrganisationModal
            visible={appSwitcherVisible}
            onClose={onCloseSwitchOrganisationModal}
          />
        </div>
      </header>
    );
  }
}

export default withFlagsConsumer(GlobalHeader);
