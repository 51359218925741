import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import isEmail from 'validator/lib/isEmail';

import { Button, Input, Link, Icon, NUMERIC_MEDIA_SIZES } from '@shootsta/common-react';
import getOrgAlias from '@shootsta/get-alias';
import LoginHeader from './LoginHeader';
import LoginHelp from './LoginHelp';

import { login, onEnterPressed } from '../../../../utils';

import '../styles/_login-form.scss';

console.log('NUMERIC_MEDIA_SIZES', NUMERIC_MEDIA_SIZES);

type Props = {
  loginRequest: Function,
  history: Object,
  orgName: string
};

type State = {
  email: string,
  password: string,
  error: string,
  submitting: boolean,
};

export default class LoginForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      submitting: false
    };
  }

  goToForgotPassword = () => {
    const { history } = this.props;

    history.push('/login/forgot');
  };

  /**
   * Updates a field in state
   *
   * @param {string} fieldName
   *
   * @return {Function}
   *
   */
  onUpdateField = (fieldName: string) => ({ target: { value } }: { target: { value: string } }) => {
    this.setState({ [fieldName]: value, error: '' });
  };

  onSubmit = async () => {
    const { loginRequest } = this.props;
    const { email, password } = this.state;
    if (!this.canSubmit(email, password)) { return; }

    this.setState({ error: '', submitting: true });

    const result = await login(loginRequest, { email, password, alias: getOrgAlias() });

    if (!result) { return; }

    this.setState(result);
  }

  canSubmit = (email: string, password: string) => email && password && isEmail(email);

  render() {
    const {
      history,
      orgName
    } = this.props;
    const {
      email,
      password,
      submitting,
      error
    } = this.state;

    return (
      <div className="login-form">
        <div className="login-form__body">
          <LoginHeader
            title={`Sign in to ${orgName}`}
            subtitle={`${window.location.hostname}`}
          />
          <form className="login-form__body__form">
            <Input
              data-cy="login-email-input"
              id="email-input"
              className="login-form__body__field"
              placeholder="Enter Email"
              value={email}
              onChange={this.onUpdateField('email')}
              prefix="email"
              label="Email Address"
              autoComplete="username"
              validate={value => !value || isEmail(value)}
              errorMessage="That doesn't look like an email address."
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
            />
            <Input
              data-cy="login-password-input"
              id="password-input"
              className="login-form__body__field"
              placeholder="Enter Password"
              value={password}
              type="password"
              onChange={this.onUpdateField('password')}
              prefix="padlock"
              autoComplete="current-password"
              label="Password"
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
            />
          </form>
          <Link
            className="login-form__body__forgot-btn"
            onClick={this.goToForgotPassword}
          >
            Forgot Password?
          </Link>
          <Button
            data-cy="login-submit"
            className="login-form__body__submit"
            disabled={!this.canSubmit(email, password)}
            onClick={this.onSubmit}
            busy={submitting}
          >
            Login
          </Button>
          <LoginHelp
            history={history}
          />
          {error
            ? (
              <div className="login-form__body__error">
                <MediaQuery maxWidth={NUMERIC_MEDIA_SIZES.small}>
                  <Icon name="alert" color="black" size={15} />
                </MediaQuery>
                <MediaQuery minWidth={NUMERIC_MEDIA_SIZES.small + 1}>
                  <Icon name="alert" color="thunderBird" size={15} />
                </MediaQuery>
                <p>{error}</p>
              </div>
            ) : null
        }
          <div className="login-form__footer" />
        </div>
      </div>
    );
  }
}
