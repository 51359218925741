import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import globalConfig from 'react-global-configuration';
import memoize from 'memoize-one';
import cx from 'classnames';
import {
  NavigationMenu,
  NavigationMenuMobile,
  hasPermission,
  LaunchCastModal,
  DownloadCastAppModal,
  withFlagsConsumer,
  NUMERIC_MEDIA_SIZES
} from '@shootsta/common-react';
import getOrgAlias from '@shootsta/get-alias';
import type { Node } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';
import { replaceBucketKeysWithUrls } from '@shootsta/client-aws-s3';

import AdminModeContext from '../../../common/contexts/AdminMode';
import {
  topNavLinks,
  bottomNavLinks,
  topAdminNavLinks,
  MEDIA_SIZES,
  REQUIRED_PERMISSIONS
} from '../../../common/constants';
import GlobalHeader from '../../../common/components/GlobalHeader';

import '../styles/_core-layout.scss';

type CoreLayoutProps = {
  children?: Node,
  location: Location,
  history: RouterHistory,
  apolloClient: Object,
  organisationId: string,
  orgPermissions: Array<string>,
  organisationPackage?: Object,
  logoUrl?: string,
  organisationName: string,
  organisationMemberships: Array<Object>,
  flags: any,
  width: number,
  canCreateOrSubmitProject: boolean
};

type CoreLayoutState = {
  adminMode: boolean,
  appSwitcherVisible: boolean,
  visibleNotificationModal: boolean,
  isCastAppLaunching: boolean,
  isDownloadAppModalVisible: boolean,
  imageUrl: string
};

type IsOpen = {
  isOpen: boolean
};

const INTERNAL_URL_SEGMENTS = ['internal', 'traffic', 'system-asset-library'];
const SHOOTSTA_ORG_ID = 'f4e7f2c7-7911-4ca9-84f0-4b3ca37249bd';

function getFirstUrlSegment(url) {
  return `/${url.split('/')[1]}`;
}

class CoreLayout extends Component<CoreLayoutProps, CoreLayoutState> {
  constructor(props: CoreLayoutProps) {
    super(props);

    const { location, history, logoUrl } = props;

    this.state = {
      appSwitcherVisible: false,
      adminMode: this.isInternalUrl(location.pathname),
      visibleNotificationModal: false,
      isCastAppLaunching: false,
      isDownloadAppModalVisible: false,
      imageUrl: logoUrl || ''
    };

    history.listen(({ pathname }) => {
      if (pathname !== '/') {
        this.setState({ adminMode: this.isInternalUrl(pathname) });
      }
    });
  }

  componentDidMount() {
    this.getPreSignedImageUrl();
  }

  getPreSignedImageUrl = async () => {
    const { organisationId, apolloClient } = this.props;
    const { imageUrl } = this.state;

    try {
      const { imageUrl: preSignedImageUrl } = await replaceBucketKeysWithUrls({
        apiClient: apolloClient,
        data: { imageUrl },
        substitutedKeys: ['imageUrl'],
        organisationId
      });

      if (preSignedImageUrl) {
        this.setState({ imageUrl: preSignedImageUrl });
      }
    } catch {
      // Silently handle error
    }
  };

  getRoutes = memoize(({ pathname }, links) => {
    const { orgPermissions, organisationPackage } = this.props;
    const isAdminOrHasPackage = orgPermissions.includes('MANAGE_ORGANISATION') || organisationPackage;

    return (
      links
        .filter(({ requiredPermission, orgPackageRequired }) => (
          (!requiredPermission || hasPermission(requiredPermission, orgPermissions))
          && (!orgPackageRequired || (orgPackageRequired && organisationPackage))
          && (requiredPermission !== 'VIDEO_TOOL' || isAdminOrHasPackage)

        ))
        .map((route) => {
          const matchedRoute = route.url === pathname || route.url === getFirstUrlSegment(pathname);

          return matchedRoute ? { ...route, selected: true } : route;
        })
    );
  }
  );

  isInternalUrl = (pathname: string) => {
    const firstUrlSegment = getFirstUrlSegment(pathname);

    return INTERNAL_URL_SEGMENTS.some(urlSegment => firstUrlSegment === `/${urlSegment}`);
  };

  toggleAdminMode = () => {
    const { history, organisationMemberships } = this.props;
    const { adminMode: isAdminMode } = this.state;

    const { organisation: shootstaOrg } = organisationMemberships
      .find(membership => membership.organisation.organisationId === SHOOTSTA_ORG_ID) || {};

    const shootstaOrgAdminUrl = `${shootstaOrg.alias}.${window.location.host.split('.').slice(1).join('.')}`;

    const trafficLink = (shootstaOrg.alias === getOrgAlias()) ? '/traffic' : `${shootstaOrgAdminUrl}/traffic`;

    const path = isAdminMode ? '/pro' : trafficLink;

    const useHistory = (shootstaOrg.alias === getOrgAlias()) || isAdminMode;

    this.setState(({ adminMode }) => ({ adminMode: !adminMode }), () => {
      if (useHistory) { return history.push(path); }

      return window.location.assign(`${window.location.protocol}//${path}`);
    });
  };

  showSwitchOrganisationModal = () => {
    this.setState({ appSwitcherVisible: true });
  };

  hideSwitchOrganisationModal = () => {
    this.setState({ appSwitcherVisible: false });
  };

  visibleNotificationModal = ({ isOpen }: IsOpen) => {
    this.setState({ visibleNotificationModal: isOpen });
  };

  createProject = () => {
    const { history } = this.props;

    history.push('/pro/builder/new');
  };

  onDownloadCastAppModal = (isModalClosedWithTimer: boolean) => {
    const { isCastAppLaunching } = this.state;

    this.setState({ isCastAppLaunching: !isCastAppLaunching });

    if (isModalClosedWithTimer) {
      this.onChangeDownloadCastModal();
    }
  };

  onChangeDownloadCastModal = () => {
    const { isDownloadAppModalVisible } = this.state;

    this.setState({ isDownloadAppModalVisible: !isDownloadAppModalVisible });
  };

  createCast = () => {
    const { history } = this.props;

    history.push('/cast');
    this.onDownloadCastAppModal(false);
  };

  createElevate = () => {
    const { history } = this.props;

    history.push('/elevate/recording');
  };

  getCreateProjectButtonProps = () => ({
    children: 'Create Project',
    onClick: this.createProject
  });

  getNewButtonProps = () => {
    const { orgPermissions, canCreateOrSubmitProject } = this.props;
    const options = [];

    if (canCreateOrSubmitProject) {
      options.push({
        label: 'Pro',
        value: 'PRO',
        prefix: 'video',
        action: this.createProject
      });
    }

    if (orgPermissions.includes(REQUIRED_PERMISSIONS.CAST)) {
      options.push({
        label: 'Cast',
        value: 'CAST',
        prefix: 'castdef',
        action: this.createCast
      });
    }

    if (orgPermissions.includes(REQUIRED_PERMISSIONS.ELEVATE)) {
      options.push({
        label: 'Elevate',
        value: 'ELEVATE',
        prefix: 'elevatedef',
        action: this.createElevate
      });
    }

    if (options.length === 0) {
      return null;
    }

    return {
      isDropdownButton: true,
      label: 'New Project',
      prefix: 'addfat',
      options
    };
  };

  render() {
    const {
      children,
      location,
      history,
      orgPermissions,
      organisationName,
      organisationId,
      apolloClient,
      flags,
      width
    }: CoreLayoutProps = this.props;

    const {
      adminMode,
      appSwitcherVisible,
      visibleNotificationModal,
      isCastAppLaunching,
      isDownloadAppModalVisible,
      imageUrl
    } = this.state;


    // TODO --> add USER_HOMEPAGE to permissions table
    orgPermissions.push('USER_HOMEPAGE');

    const HIDE_FEATURES = globalConfig.get('HIDE_FEATURES');

    const topLinks = adminMode ? topAdminNavLinks.filter(Boolean) : topNavLinks.filter(Boolean);

    // Filter navLinks based on HIDE_FEATURES flags
    const filteredTopLinks = topLinks.filter(({ requiredPermission, url }) => {
      if (requiredPermission === 'CAST') { return !HIDE_FEATURES.cast; }

      if (url === '/internal/all-projects') { return !HIDE_FEATURES.allVideos; }

      if (url === '/elevate') { return !HIDE_FEATURES.elevateLandingPage; }

      if (url === '/system-asset-library/pro') { return !HIDE_FEATURES.systemAssetLibrary; }

      return true;
    });

    const topRoutes = this.getRoutes(location, filteredTopLinks, adminMode);
    const bottomRoutes = this.getRoutes(location, bottomNavLinks, adminMode);
    const newButtonProps = HIDE_FEATURES.cast
      ? this.getCreateProjectButtonProps()
      : this.getNewButtonProps();

    const isMobile = width < NUMERIC_MEDIA_SIZES.small;

    return (
      <div className={cx('core-layout', { 'core-layout__no-scroll': appSwitcherVisible })}>
        <MediaQuery minWidth={MEDIA_SIZES.small + 1}>
          <NavigationMenu
            history={history}
            topRoutes={topRoutes}
            bottomRoutes={bottomRoutes}
            primaryColour={adminMode ? 'monkey' : void 0}
            primaryLinksColour={adminMode ? 'white' : void 0}
            primaryLinksHoverColour={adminMode ? 'aqua' : void 0}
            primaryLinksSelectedColour={adminMode ? 'bermudaBlue' : void 0}
            isExpandable
            logoUrl={adminMode ? void 0 : imageUrl}
            title={organisationName}
            primaryButtonProps={(flags && flags.hideCreateProject) ? undefined : newButtonProps}
          />
        </MediaQuery>
        <div className="core-layout__content">
          <AdminModeContext.Provider value={adminMode}>
            <GlobalHeader
              showSwitchOrganisationModal={this.showSwitchOrganisationModal}
              appSwitcherVisible={appSwitcherVisible}
              onCloseSwitchOrganisationModal={this.hideSwitchOrganisationModal}
              toggleAdminMode={hasPermission('INTERNAL', orgPermissions) && this.toggleAdminMode}
              adminMode={adminMode}
              showMobileNotification={visibleNotificationModal}
              toggleMobileNotification={() => this.visibleNotificationModal({ isOpen: false })}
            />
            <MediaQuery maxWidth={MEDIA_SIZES.small}>
              {!visibleNotificationModal && (
                <NavigationMenuMobile
                  history={history}
                  topRoutes={topRoutes}
                  bottomRoutes={bottomRoutes}
                  primaryColour={adminMode ? 'monkey' : void 0}
                  primaryLinksColour={adminMode ? 'white' : void 0}
                  primaryLinksSelectedColour={adminMode ? 'bermudaBlue' : void 0}
                  isExpandable
                  logoUrl={imageUrl}
                  onNotificationsClick={() => this.visibleNotificationModal({ isOpen: true })}
                  toggleAdminMode={hasPermission('INTERNAL', orgPermissions) && this.toggleAdminMode}
                  adminMode={adminMode}
                  primaryButtonProps={(flags && flags.hideCreateProject)
                    ? undefined
                    : newButtonProps}
                />
              )}
            </MediaQuery>
            {children}
          </AdminModeContext.Provider>
        </div>
        {isCastAppLaunching && (
          <LaunchCastModal
            history={history}
            isVisible={isCastAppLaunching}
            onClose={this.onDownloadCastAppModal}
            organisationId={organisationId}
            apolloClient={apolloClient}
            isMobile={isMobile}
          />
        )}
        {isDownloadAppModalVisible && (
          <DownloadCastAppModal
            isVisible={isDownloadAppModalVisible}
            onClose={this.onChangeDownloadCastModal}
          />
        )}

      </div>
    );
  }
}
export default withFlagsConsumer(CoreLayout);
