import {
  PUBLIC_ROUTES,
  ROUTE
} from '../constants/publicRoutes';

const getIsSomeOfRoutes = (path: string, routes: string[]) => (
  routes.some((route: string) => path.includes(route))
);

export const getIsPublicUploadLink = (path: string) => (
  getIsSomeOfRoutes(path, [ROUTE.PUBLIC_UPLOAD])
);

export const getIsPublicLink = (path: string): boolean => (
  getIsSomeOfRoutes(path, PUBLIC_ROUTES)
);
