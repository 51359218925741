import { gql } from 'apollo-boost';

const GET_ORGANISATION_NAME_FROM_ALIAS = gql`
    query getOrganisationNameFromAlias($alias: String!) {
      getOrganisationNameFromAlias(alias: $alias){
        name
      }
    }
`;

export default () => ({
  mutation: GET_ORGANISATION_NAME_FROM_ALIAS,
  module: 'users',
  buildVariables: ({ alias }: {alias: string}) => ({ alias })
});
