// @flow
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Error as ErrorComponent } from '@shootsta/common-react';
import type { Node } from 'react';
import { identityGhostUser } from '../../../utils';
import { GHOST } from '../../../constants';
import config from '../../../../config';

export default function Ghost({ children, ghostStart }: { children: Node, ghostStart: Function }) {
  const { [GHOST]: ghost } = queryString.parse(window.location.search);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (ghost && !Array.isArray(ghost) && !config.HIDE_FEATURES.LOGIN_VIA_IDENTITY) {
      identityGhostUser(ghostStart, ghost)
        .catch(e => setError(e));
    }
  }, [ghost]);

  if (error) {
    return <ErrorComponent />;
  }

  return children;
}
