// @flow

import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { AuthorisationConsumer, RequestProvider } from '@shootsta/common-react';
import { SizeMe } from 'react-sizeme';
import MediaQuery from 'react-responsive';
import type { RouterHistory as History } from 'react-router-dom';

import GlobalHeaderComponent from '../components/GlobalHeader';
import RemoteNotifications from '../../RemoteNotifications';
import { MEDIA_SIZES } from '../../../constants';
import NotificationsSummary from '../components/NotificationsSummary';
import { ghostStopApi } from '../../../api';
import config from '../../../../config';

type GlobalHeaderContainerProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  history: History,
  adminMode: boolean,
  toggleAdminMode: Function,
  showMobileNotification: boolean,
  toggleMobileNotification: Function
};

function GlobalHeader(props: GlobalHeaderContainerProps) {
  const { showMobileNotification, toggleMobileNotification } = props;

  return (
    <ApolloConsumer>
      {apolloClient => (
        <AuthorisationConsumer>
          {({ selectedOrganisation }) => (
            <RemoteNotifications selectedOrganisation={selectedOrganisation}>
              {notifications => (
                <SizeMe>
                  {({ size: { width } = { width: 0 } }) => (
                    <RequestProvider requests={{
                      ghostStop: ghostStopApi()
                    }}
                    >
                      {({ ghostStop }) => (
                        <>
                          <GlobalHeaderComponent
                            {...props}
                            selectedOrganisation={selectedOrganisation}
                            apolloClient={apolloClient}
                            notifications={notifications}
                            width={width}
                            ghostStop={ghostStop}
                            loginViaIdentity={!config.HIDE_FEATURES.LOGIN_VIA_IDENTITY}
                          />
                          {showMobileNotification && (
                            <MediaQuery maxWidth={MEDIA_SIZES.small}>
                              <NotificationsSummary
                                showMobileNotification={showMobileNotification}
                                toggleMobileNotification={toggleMobileNotification}
                                {...notifications}
                              />
                            </MediaQuery>
                          )}
                        </>
                      )}
                    </RequestProvider>
                  )}
                </SizeMe>
              )}
            </RemoteNotifications>
          )}
        </AuthorisationConsumer>
      )}
    </ApolloConsumer>
  );
}

export default withRouter(GlobalHeader);
