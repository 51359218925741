import React, { Component } from 'react';
import moment from 'moment';
import _memoize from 'lodash.memoize';
import { credentials } from '@shootsta/client-auth';
import { Dropdown, Image, Link, Text } from '@shootsta/common-react';
import { replaceBucketKeysWithUrlsInArray } from '@shootsta/client-aws-s3';
import type { RouterHistory as History } from 'react-router-dom';
import { getTimeRemaining, getLatestOutputVersion } from '../utils';
import { STATUS_COLORS_MAP } from '../../../constants/colors';

import '../styles/_outputs-list.scss';

type OutputsListProps = {
  getTrafficOutputs: Function,
  apolloClient: Object,
  history: History
}

type OutputsListState = {
  outputs: Array<Object>,
  count: number
}

export default class OutputsList extends Component<OutputsListProps, OutputsListState> {
  constructor(props: OutputsListProps) {
    super(props);

    this.state = {
      outputs: [],
      count: 0
    };
  }

  componentDidMount(): void {
    this.fetchOutputs();
  }

  fetchOutputs = async () => {
    const { getTrafficOutputs, apolloClient } = this.props;

    const { error, data } = await getTrafficOutputs();

    if (error || !data || !data.getTrafficOutputs) { return; }

    const { outputs = [], count = 0 } = data.getTrafficOutputs || {};

    try {
      const outputsWithImages = await replaceBucketKeysWithUrlsInArray({
        apiClient: apolloClient,
        dataArray: outputs,
        substitutedKeys: [{ key: 'video', subKeys: [{ key: 'organisation', subKeys: ['imageUrl'] }] }]
      });

      this.setState({
        outputs: outputsWithImages,
        count
      });
    } catch {
      this.setState({ outputs: [], count });
    }
  };

  renderOutputItem = _memoize(({ outputId, versions, status, video }: Object) => {
    const {
      dueDate,
      onHoldDate
    }: {
      dueDate?: string,
      appliedAddons?: { RUSH_EDIT?: { value: string } },
      onHoldDate?: string
    } = getLatestOutputVersion(versions);
    const { organisation, name, videoId } = video;
    const { hoursLeft, color } = getTimeRemaining({ dueDate, onHoldDate });
    const { history } = this.props;

    return (
      <Dropdown.Label
        key={outputId}
        className="outputs-list__output"
        selectOption={() => history.push(`/traffic/video/${videoId}`)}
        data={{
          label: (
            <>
              <Image
                imageUrl={organisation.imageUrl}
                width="30px"
                height="30px"
                rounded
                defaultIconSize={20}
              />
              <div className="outputs-list__output__info">
                <Text ellipsisOverflow noMargin label className="outputs-list__output__info__name">
                  {name}
                </Text>
                <Text
                  label
                  {...STATUS_COLORS_MAP[status.name]}
                  className="outputs-list__output__info__status"
                  noMargin
                  caption
                >
                  {status.name.toLowerCase()}
                </Text>
              </div>
              <Text highlighted {...color}>{hoursLeft}</Text>
            </>
          )
        }}
      />
    );
  });

  render() {
    const { outputs, count } = this.state;
    const { userId } = credentials.get().user;

    return (
      <div className="outputs-list">
        <Text noMargin>{`${count} video${count !== 1 ? 's' : ''} due`}</Text>
        <Text timestamp noMargin="top">{moment().format('Do MMMM YYYY')}</Text>
        {outputs.map(this.renderOutputItem)}
        <Link
          className="outputs-list__all"
          to={`/traffic?EDITOR=${userId}&STATUS=EDITING,PENDING_MASTER`}
        >
          View All
        </Link>
      </div>
    );
  }
}
