// @flow

import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import VerificationComponent from '../components/Verification';
import { getVerificationCodeApi, getOrgsForUserCodeApi } from '../api';

const Verification = ({ history, client }: { history: RouterHistory, client: Object }) => (
  <RequestProvider
    requests={{
      getVerificationCode: getVerificationCodeApi(),
      getOrgsForUserCode: getOrgsForUserCodeApi()
    }}
  >
    {({ getVerificationCode, getOrgsForUserCode }) => (
      <VerificationComponent
        history={history}
        client={client}
        getVerificationCode={getVerificationCode}
        getOrgsForUserCode={getOrgsForUserCode}
      />
    )}
  </RequestProvider>
);

export default withRouter(Verification);
