import { gql } from 'apollo-boost';

const GHOST_STOP_QUERY = gql`
    query {
        ghostStop
    }
`;

export default () => ({
  query: GHOST_STOP_QUERY,
  module: 'identity',
  ignoreCache: true,
  buildVariables: () => ({})
});
