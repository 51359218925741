import { useEffect } from 'react';

const useInfiniteScroll = (fetchFunc: Function) => {
  useEffect(() => {
    const { addEventListener, removeEventListener } = document;

    const listenerCallback = ({
      target: { scrollHeight, scrollTop, clientHeight }
    }: Object) => {
      const scrollBottom = scrollHeight - scrollTop === clientHeight;

      if (!scrollBottom) { return; }

      fetchFunc();
    };

    addEventListener('scroll', listenerCallback, true);

    return () => { removeEventListener('scroll', listenerCallback, true); };
  });
};

export default useInfiniteScroll;
