import React, { useEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import { Text, Carousel, List, ElevatedContainer } from '@shootsta/common-react';
import './_how-to-carousel.scss';

const MEDIA_SIZES_SMALL = 639;

type HowToCard = {
  text: string,
  imagePath: string,
  link?: string
}

type HowToCarouselProps = {
  carouselCards: Array<HowToCard>,
};

function HowToCarousel({ carouselCards }: HowToCarouselProps) {
  const [pageWidth, setPageWidth] = useState(0);
  const [didResize, setDidResize] = useState(true);

  const HowToCarouselCardItem = ({ text, imagePath, link }: HowToCard) => (
    <List.CardItem key={text}>
      <a
        className="card-link"
        target="_blank"
        href={link}
        rel="noopener noreferrer"
      >
        <div
          className="card-image"
          style={{ backgroundImage: `url(${imagePath})` }}
        />
        <div className="card-text" background>
          <Text>{text}</Text>
        </div>
      </a>
    </List.CardItem>
  );

  useEffect(() => {
    setDidResize(false);
    setTimeout(() => {
      setDidResize(true);
    }, 10);
  }, [pageWidth]);

  useEffect(() => {
    setDidResize(false);
    setTimeout(() => {
      setDidResize(true);
    }, 10);
  }, [pageWidth]);

  return (
    <SizeMe>
      {({ size: { width } }) => {
        setPageWidth(width);
        return (
          didResize ? (
            <ElevatedContainer rounded className="how-to-carousel">
              <Carousel itemWidth="25%" slidesToShow={width < MEDIA_SIZES_SMALL && 1}>
                {carouselCards.map(card => HowToCarouselCardItem(card))}
              </Carousel>
            </ElevatedContainer>
          ) : null
        );
      }}
    </SizeMe>
  );
}
export default HowToCarousel;
