import { gql } from 'apollo-boost';

const GET_USER_ORGANISATIONS = gql`
    query getUserOrganisations($email: String!) {
        getUserOrganisations(email: $email) {
            organisationId
            name
            alias
            imageUrl
        }
    }
`;

export default () => ({
  mutation: GET_USER_ORGANISATIONS,
  module: 'users',
  buildVariables: ({ email }: { email: string }) => ({ email })
});
