// @flow
import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import MediaQuery from 'react-responsive';
import { Form, FormModal, Input, Image } from '@shootsta/common-react';
import '../styles/_switch-organisation-modal.scss';

type SwitchOrganisationModalProps = {
  onClose: Function,
  visible: boolean,
  updateSelectedOrganisation?: Function,
  organisations: Array<Object>,
  selectedOrganisation: Object
}

type SwitchOrganisationModalState = {
  filter: string
}

function itemMatchesFilter(item, filter) {
  return Object.values(item).some(
    itemField => itemField
      && typeof itemField === 'string'
      && itemField.toLowerCase().includes(filter.toLowerCase())
  );
}

export default class SwitchOrganisationModal extends Component<
  SwitchOrganisationModalProps,
  SwitchOrganisationModalState
> {
  applyFilter = memoizeOne(
    (filter, list) => list.filter(item => itemMatchesFilter(item, filter)),
    ([newFilter, newList], [oldFilter, oldList]) => newFilter === oldFilter && newList === oldList
  );

  handleSwitchOrganisation = memoizeOne((switchedOrganisation: Object) => () => {
    const { updateSelectedOrganisation, onClose, selectedOrganisation } = this.props;

    if (!updateSelectedOrganisation || switchedOrganisation === selectedOrganisation) {
      return void onClose();
    }

    updateSelectedOrganisation(switchedOrganisation);
    onClose();
  });

  constructor(props: SwitchOrganisationModalProps) {
    super(props);

    this.state = {
      filter: ''
    };
  }
  // eslint-disable-next-line no-undef
  handleSetFilter = ({ target: { value } }: SyntheticInputEvent<EventTarget>) => {
    this.setState({ filter: value });
  };

  renderItemBlock = (orgItem: Object) => {
    const { imageUrl, name, organisationId } = orgItem;

    return (
      <div key={organisationId} className="organisations-list__list-item">
        <MediaQuery minWidth={865}>
          <Image
            imageUrl={imageUrl}
            placeholderText={name}
            width="75px"
            height="75px"
            backgroundSize="cover"
            rounded
          />
        </MediaQuery>
        <div className="organisations-list__list-item__content-desc">
          <Form.FormBlockDescription
            className="organisations-list__form-block"
            name={name}
            description=""
            onClickButton={this.handleSwitchOrganisation(orgItem)}
            buttonText="Sign In"
          />
        </div>
      </div>
    );
  };

  render() {
    const { visible, onClose, organisations = [] } = this.props;
    const { filter } = this.state;
    const filteredOrganisations = filter ? this.applyFilter(filter, organisations) : organisations;

    return (
      <FormModal
        title="Sign In To Another Workspace"
        subtitle="You can change between any organisations you're a member of"
        visible={visible}
        onClose={onClose}
        hideFooter
      >
        <div className="organisations-list">
          <Input
            className="organisations-list__search"
            prefix="search"
            placeholder="Search your workspaces"
            onChange={this.handleSetFilter}
          />
          {filteredOrganisations.map(this.renderItemBlock)}
          {!filteredOrganisations.length ? <p className="organisations-list__list-container__empty-text">Nothing to show</p> : null}
        </div>
      </FormModal>
    );
  }
}
