import getOrgAlias from '@shootsta/get-alias';
import { SHOOTSTA_ALIAS } from '../common/constants';

const getHomePage = (
  orgPreferences: { homepage?: string },
  organisationPackage?: { subEnd?: string },
  isShootstaEditor?: boolean,
  HIDE_FEATURES?: Object
) => {
  const orgAlias = getOrgAlias();

  if (orgPreferences && orgPreferences.homepage) {
    return orgPreferences.homepage;
  }

  // Traffic is for internal users only at the moment...
  // might change in future but not required to generalise now
  if (isShootstaEditor && orgAlias === SHOOTSTA_ALIAS) {
    return '/traffic';
  }

  if (HIDE_FEATURES && HIDE_FEATURES.userHomepage) { return '/cast'; }

  return '/home';
};

export default getHomePage;
