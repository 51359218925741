import React from 'react';
import { withRouter } from 'react-router-dom';
import { AuthorisationConsumer, RequestProvider, PageHeader, GlobalStoreConsumer } from '@shootsta/common-react';

import { credentials } from '@shootsta/client-auth';
import { getUserRecentActivityApi, getAssetThumbnailApi, getUserRecentElevatesApi } from '../api';

import UserHomepage from '../components/UserHomepage';
import './styles/_user-homepage.scss';


function UserHomepageContainer() {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation }) => {
        const { name, organisationId } = selectedOrganisation;
        const { firstName } = credentials.get().user;

        return (
          <RequestProvider
            requests={{
              getUserRecentActivity: getUserRecentActivityApi(),
              getUserRecentElevates: getUserRecentElevatesApi(),
              getAssetThumbnail: getAssetThumbnailApi(organisationId)
            }}
          >
            {({ getUserRecentActivity, getAssetThumbnail, getUserRecentElevates }) => (
              <GlobalStoreConsumer>
                {globalStore => (
                  <div className="user-homepage page">
                    <PageHeader
                      title={`Hey ${firstName}!`}
                      subtitle={name}
                    />
                    <UserHomepage
                      organisation={selectedOrganisation}
                      getUserRecentActivity={getUserRecentActivity}
                      getUserRecentElevates={getUserRecentElevates}
                      getAssetThumbnail={getAssetThumbnail}
                      globalStore={globalStore}
                    />
                  </div>
                            )}
              </GlobalStoreConsumer>
            )}
          </RequestProvider>
        );
      }}

    </AuthorisationConsumer>
  );
}

export default withRouter(UserHomepageContainer);
