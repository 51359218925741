import { RETURN_TO, TTL } from '../constants';
import { getIdentityUrl } from '../components/IdentityLogin/utils';

const getURLParams = (): URLSearchParams => new URLSearchParams(window.location.search || '');

export const getRedirectTTL = (): string | null => getURLParams().get(TTL) || null;

export const canReauthenticate = (): boolean => {
  const redirectTTL = getRedirectTTL();

  return !redirectTTL || redirectTTL !== '0';
};

export default function identityReauthenticateRedirect() {
  if (!canReauthenticate()) {
    throw new Error('The redirect TTL is 0. Redirection stopped.');
  }

  const returnTo = window.location.href;
  const returnToMinusProtocol = returnTo.replace(/^https?:\/\//, '');
  const url = new URL(returnTo);
  const queryParams = new URLSearchParams(url.search);

  queryParams.set(RETURN_TO, returnToMinusProtocol);
  queryParams.set(TTL, '1');

  window.location.href = (new URL(`?${queryParams.toString()}`, getIdentityUrl().href)).href;
}
