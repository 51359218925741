import gql from 'graphql-tag';


const getAssets = gql`
  query GetAssets($organisationId: String, $assetIds: [String]) {
    getAssets(organisationId: $organisationId, assetIds: $assetIds) {
      assetId
      organisationId
      filename
      permissions
      assetFiles {
        THUMBNAIL {
          sourceUrl
        }
      }
      assetRelations {
        assetRelationId
      }
    }
  }
`;

export default (organisationId: string) => ({
  query: getAssets,
  module: 'assets',
  ignoreCache: true,
  buildVariables: ({ assetIds }: { assetIds: [string] }) => ({
    organisationId, assetIds
  })
});
