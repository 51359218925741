// @flow
import React, { Component } from 'react';
import moment from 'moment';
import _isEqual from 'lodash.isequal';
import _isEmpty from 'lodash.isempty';
import _get from 'lodash.get';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Icon, Link, MenuButton, Text, notify } from '@shootsta/common-react';
import NotificationItem from '../NotificationItem';
import ViewAllNotifications from '../ViewAllNotifications';
import {
  sortDedupNotifications,
  buildNotificationString
} from '../../../../../utils';
import type { Notification } from '../../../../../types/notification';
import './styles/_notifications-summary.scss';

type NotificationSummaryProps = {
  newNotification: { notificationCreated: Notification },
  requests: Object,
  showMobileNotification: boolean,
  toggleMobileNotification: Function
};

type NotificationSummaryState = {
  userNotifications: Notification[],
  unreadNotificationsCount: number,
  viewAllNotificationsVisible: boolean,
  loading: boolean,
  notificationsCount: number
}

export default class NotificationsSummary extends Component<
  NotificationSummaryProps,
  NotificationSummaryState
  > {
  _summaryItemsRef: any;

  constructor(props: NotificationSummaryProps) {
    super(props);

    this._summaryItemsRef = React.createRef();

    const { showMobileNotification } = props;

    this.state = {
      userNotifications: [],
      unreadNotificationsCount: 0,
      viewAllNotificationsVisible: showMobileNotification,
      loading: false,
      notificationsCount: 0
    };
  }

  componentDidMount() {
    this.fetchNotifications();
    this.fetchUnreadNotificationCount();
  }

  componentDidUpdate(
    { newNotification: prevNewNotification }: NotificationSummaryProps,
    { userNotifications: prevUserNotifications }: NotificationSummaryState
  ) {
    const { newNotification: currNewNotification } = this.props;
    const { userNotifications: currUserNotifications } = this.state;

    const sortedIds = (notifications: Notification[]) => (
      notifications.map(({ notificationId }) => notificationId).sort()
    );

    const getChangedNotifications = (curr?: Notification[], prev?: Notification[]) => (
      curr && prev && !_isEqual(sortedIds(curr), sortedIds(prev)) && curr
    );

    const userNotificationsChanged = getChangedNotifications(
      currUserNotifications, prevUserNotifications
    );

    const newNotification = !_isEqual(currNewNotification, prevNewNotification)
      && currNewNotification;

    if (newNotification) {
      this.setNewNotification(newNotification);
      return;
    }

    if (!userNotificationsChanged) {
      return;
    }

    this.setNotifications({ notifications: userNotificationsChanged, hasUserInfo: true });
  }

  setUserInformation = async (notification: Notification) => {
    const { sourceUserId, sourceUser } = notification;

    if (sourceUserId && sourceUser) {
      const { userId, firstName, lastName, imageUrl } = sourceUser;

      return {
        ...notification,
        sourceUserId: userId,
        fullName: `${firstName} ${lastName}`,
        imageUrl
      };
    }

    return notification;
  };

  setNewNotification = async (notification: { notificationCreated: Notification }) => {
    const notificationCreated = _get(notification, 'notificationCreated');
    const newNotification = await this.setUserInformation(notificationCreated);

    if (_isEmpty(newNotification)) { return; }

    const { fullName, type, resource } = newNotification;

    notify(buildNotificationString({
      notificationType: type,
      fullName,
      resource
    }));

    this.setState(({ userNotifications, unreadNotificationsCount }) => ({
      userNotifications: [newNotification, ...userNotifications],
      unreadNotificationsCount: unreadNotificationsCount + 1
    }));
  };

  setNotifications = async ({
    notifications,
    notificationsCount: newNotificationsCount,
    hasUserInfo = false,
    append = false
  }: {
    notifications: Notification[],
    notificationsCount?: number,
    hasUserInfo?: boolean,
    append?: boolean
  }) => {
    const newUserNotifications = hasUserInfo
      ? notifications
      : await Promise.all(notifications.map(notification => (
        this.setUserInformation(notification)))
      );

    return void this.setState(({
      userNotifications: prevUserNotifications,
      notificationsCount: prevNotificationsCount
    }) => {
      const userNotifications = sortDedupNotifications(append
        ? [...prevUserNotifications, ...newUserNotifications]
        : newUserNotifications);
      const notificationsCount = newNotificationsCount !== void 0
        ? newNotificationsCount
        : prevNotificationsCount;

      return { notificationsCount, userNotifications, loading: false };
    });
  };

  openViewAllNotifications = () => {
    const { viewAllNotificationsVisible } = this.state;

    return !viewAllNotificationsVisible
      && void this.setState({ viewAllNotificationsVisible: true });
  };

  closeViewAllNotifications = () => {
    const { viewAllNotificationsVisible } = this.state;

    return viewAllNotificationsVisible
      && void this.setState({ viewAllNotificationsVisible: false });
  };

  markAllNotificationsRead = (unreadNotifications: Notification[]) => async () => {
    const { requests: { markAllAsReadRequest } } = this.props;

    await markAllAsReadRequest();

    const { userNotifications: currUserNotifications } = this.state;
    const readNotifications = currUserNotifications.filter(({ read }) => read);
    const markedReadNotifications = unreadNotifications.map(notification => ({
      ...notification,
      read: true
    }));
    const userNotifications = [...readNotifications, ...markedReadNotifications];

    this.setState({ userNotifications, unreadNotificationsCount: 0 });
  };

  handleNotificationLinkSelect = (notificationId: string) => async () => {
    const { requests: { setNotificationsReadRequest } } = this.props;
    const { userNotifications, unreadNotificationsCount } = this.state;

    await setNotificationsReadRequest({ notificationIds: [notificationId] });

    this.fetchUnreadNotificationCount();

    const update = userNotifications
      .find(notif => notif.notificationId === notificationId);

    const rest = userNotifications
      .filter(notif => notif.notificationId !== notificationId);

    this.setState({
      userNotifications: [...rest, { ...update, read: true }],
      unreadNotificationsCount: unreadNotificationsCount - 1
    });
  };

  fetchUnreadNotificationCount = async () => {
    const { requests: { getUnreadCountRequest } } = this.props;

    const { data } = await getUnreadCountRequest();

    if (!data || !data.getUnreadCount) {
      return;
    }

    const { getUnreadCount: unreadNotificationsCount } = data;

    return void this.setState({ unreadNotificationsCount });
  };

  fetchNotifications = async () => {
    const { userNotifications } = this.state;
    const { requests: { getNotificationsAndCountRequest } } = this.props;

    this.setState({ loading: true });

    const { data: responseData } = await getNotificationsAndCountRequest({
      offset: userNotifications.length
    });

    if (!responseData || !responseData.getNotificationsAndCount) {
      return;
    }

    const data = responseData.getNotificationsAndCount;

    this.setNotifications({
      notifications: data.notifications,
      notificationsCount: data.count,
      append: true
    });
  };

  getSummaryItemsRef = () => this._summaryItemsRef.current;

  render() {
    const { requests, toggleMobileNotification } = this.props;

    const {
      userNotifications,
      viewAllNotificationsVisible,
      loading,
      notificationsCount,
      unreadNotificationsCount
    } = this.state;

    const unreadNotifications = userNotifications.filter(({ read }) => !read);

    const haveUnreadNotifications = unreadNotificationsCount > 0;
    const unreadNotificationsNum = haveUnreadNotifications
      ? `(${unreadNotificationsCount})`
      : '';

    const notificationsMap = userNotifications.map((notification: Notification) => {
      const {
        notificationId,
        fullName,
        resource,
        link,
        type,
        read,
        createdAt
      } = notification;
      const relativeCreatedAt = moment(createdAt, ['x', 'YYYY-MM-DDTHH:mm:ss.sssZ']).fromNow();

      return (
        <div key={notification.notificationId} data-key={notification.notificationId}>
          <NotificationItem
            notificationId={notificationId}
            fullName={fullName || ''}
            resource={resource}
            link={link}
            read={read}
            type={type}
            displayedCreatedAt={relativeCreatedAt}
            onLinkSelect={this.handleNotificationLinkSelect}
            className="notifications-summary"
          />
          <hr className="notifications-summary__horizontal-rule" />
        </div>
      );
    });

    const getMarkAllAsReadLink = (classPrefix: string) => haveUnreadNotifications && (
      <Link
        className={`${classPrefix}__mark-read`}
        onClick={this.markAllNotificationsRead(unreadNotifications)}
      >
        Mark All As Read
      </Link>
    );

    const fetchedAllNotifications = notificationsCount === 0
      || userNotifications.length >= notificationsCount;

    return (
      <div className="notifications-summary__container">
        <MenuButton
          customButton={<Icon name="notifications" size={20} />}
          align="end"
          id="notifications-button"
        >
          <div
            className="notifications-summary"
          >
            <div className="notifications-summary__header">
              <Text noMargin heading4>{`Notifications ${unreadNotificationsNum}`}</Text>
              {getMarkAllAsReadLink('notifications-summary__header')}
            </div>
            <hr className="notifications-summary__horizontal-rule" />
            <div className="notifications-summary__items" ref={this._summaryItemsRef}>
              {
                !fetchedAllNotifications || notificationsMap.length > 0
                  ? (
                    <InfiniteScroll
                      next={this.fetchNotifications}
                      hasMore={!loading && (!notificationsCount || !fetchedAllNotifications)}
                      getScrollParent={this.getSummaryItemsRef}
                      useWindow={!this.getSummaryItemsRef()}
                      threshold={20}
                      dataLength={notificationsCount}
                    >

                      {notificationsMap}
                    </InfiniteScroll>
                  )
                  : (
                    <div className="notifications-summary__no-items">
                      No new notifications
                    </div>
                  )
              }
            </div>
            <Button
              className="notifications-summary__view-all-button"
              onClick={this.openViewAllNotifications}
            >
              View All
            </Button>
          </div>
        </MenuButton>
        {
          haveUnreadNotifications && (
            <div className="notifications-summary__indicator">
              <span className="notifications-summary__indicator__text">
                {unreadNotificationsCount > 99 ? '99' : unreadNotificationsCount}
              </span>
            </div>
          )
        }
        {viewAllNotificationsVisible && (
          <ViewAllNotifications
            notifications={userNotifications}
            onCloseViewAllNotifications={toggleMobileNotification || this.closeViewAllNotifications}
            onLinkSelect={this.handleNotificationLinkSelect}
            requests={requests}
            fetchNotifications={this.fetchNotifications}
            unreadNotificationsCount={unreadNotificationsCount}
            getMarkAllAsReadLink={getMarkAllAsReadLink}
          />
        )}
      </div>
    );
  }
}
